@charset "UTF-8";
@font-face {
  font-family: "wild";
  src: url("../fonts/wild.eot");
  src: url("../fonts/wild.eot?#iefix") format("embedded-opentype"), url("../fonts/wild.woff") format("woff"), url("../fonts/wild.ttf") format("truetype"), url("../fonts/wild.svg#wild") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "wild" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "wild" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-user:before {
  content: "\61";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.icon-store:before {
  content: "\62";
}
.icon-small-down:before {
  content: "\63";
}
.icon-customer:before {
  content: "\64";
}
.icon-content:before {
  content: "\65";
}
.icon-catolougue:before {
  content: "\66";
}
.icon-delete:before {
  content: "\67";
}
.icon-edit:before {
  content: "\68";
}
.icon-search-find:before {
  content: "\6d";
}
.icon-bell:before {
  content: "\6e";
}
.icon-graph-bar:before {
  content: "\6f";
}
.icon-edit-1:before {
  content: "\69";
}
.icon-trash-b:before {
  content: "\6b";
}
.icon-cog-gear:before {
  content: "\6c";
}
.icon-widget:before {
  content: "\6a";
}
.icon-cross:before {
  content: "\70";
}
.icon-cloud-download-2:before {
  content: "\71";
}
.icon-download:before {
  content: "\72";
}
.icon-download-1:before {
  content: "\73";
}
.icon-download-2:before {
  content: "\74";
}
.icon-page-copy:before {
  content: "\75";
}
.icon-eye:before {
  content: "\76";
}
.icon-page-export-csv:before {
  content: "\77";
}
.icon-ios-upload-outline:before {
  content: "\78";
}
.tim-row {
  margin-bottom: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.border {
  border: 1px solid #dedddd;
}
.text-btn {
  color: #11817E;
  font-weight: 700;
  background-color: transparent;
  padding: 5px;
}
.text-btn:hover {
  background-color: #11817E;
}
.tim-white-buttons {
  background-color: #777777;
}
.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #11817E !important;
  border-color: #11817E !important;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
button:focus {
  outline: none;
}
.errorMsg,
.error {
  color: #dd0404;
  font-weight: 300;
  font-size: 12px;
  display: block;
  padding: 0 5px;
  margin: 0;
  width: 100%;
}
.alert {
  padding: 0 10px;
}
:focus {
  outline: none;
}
.green {
  color: #4AD991;
}
.pr-10 {
  padding-right: 10px;
}
.red {
  color: #FF7285;
}
.ab-sr {
  position: absolute;
  right: 10px;
  top: 0;
}
.text-split {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}
.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}
.tim-row {
  padding-top: 60px;
}
.tim-row h3 {
  margin-top: 0;
}
.offline-doc .page-header {
  display: flex;
  align-items: center;
}
.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.avatar-link {
  padding: 3px !important;
}
.header-form {
  width: 60%;
  margin: 0 auto;
}
.header-form .input-group.no-border .form-control {
  color: #565864;
  background-color: transparent;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  border-color: #f1f1f6;
  padding: 8px 19px;
}
.header-form .input-group.no-border .form-control + .input-group-append .input-group-text {
  background-color: transparent;
  border: medium none;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  border-color: #f1f1f6;
  color: #bcbccb;
  padding: 4px 19px 8px 0;
}
.header-form .form-control,
.header-form .input-group-append .input-group-text {
  border-radius: 0;
}
.card-dash {
  padding: 20px;
}
.card-dash .card-logo .icon-img {
  width: 75px;
}
.card {
  border: 1px solid transparent;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.card .card-category {
  color: #bbbac4;
}
.card .card-title {
  font-weight: 600;
  color: #4d4f5c;
}
.sidebar .nav li > a.active {
  border-left: 3px solid #11817E;
}
.product-wrapper .grouped-product .row:not(:last-child)::after {
  height: 1px;
  display: block;
  width: 100%;
  background: #dedddd;
  margin: 0 20px;
  content: '';
  position: relative;
  top: 10px;
}
.user-detils {
  background-color: #fff;
  padding: 10px;
}
.user-detils .title h5 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
  color: #373a36;
}
.user-detils .flex-fill .small-name {
  color: #373a36;
  font-size: 12px;
  margin-bottom: 6px;
}
.user-detils .flex-fill .large-name {
  color: #373a36;
  font-size: 16px;
  font-weight: 700;
}
.user-detils .flex-fill .large-name .text-btn {
  margin: 0;
}
.product-wrapper {
  background-color: #fff;
}
.product-wrapper .product-header {
  background-color: #f5f6fa;
  padding: 10px 20px;
}
.product-wrapper .product-header .col-name {
  font-size: 14px;
  font-weight: 500;
  color: #a4a7b5;
}
.product-wrapper .image-name .p-name {
  color: #4d4f5c;
  font-size: 13px;
  margin: 5px;
}
.product-wrapper .image-name .v-name {
  color: #a4a7b5;
  font-size: 13px;
  margin: 5px;
}
.product-wrapper .image-name img {
  width: 50px;
  height: 40px;
  border-radius: 6px;
}
.product-wrapper .grouped-product {
  border: 1px solid #dedddd;
  margin-bottom: 20px;
  border-radius: 6px;
}
.product-wrapper .grouped-product .row {
  padding: 10px;
}
.product-wrapper .body-wrapper {
  background-color: #fff;
  padding: 10px;
  min-height: calc(100vh - 12px);
  margin-bottom: 50px;
}
.product-wrapper .body-wrapper .name {
  color: #4d4f5c;
  font-size: 13px;
}
.product-wrapper .body-wrapper .price {
  color: #4d4f5c;
  font-size: 13px;
  margin: 5px;
}
.group-action .btn {
  display: inline-flex;
  background-color: transparent;
  box-shadow: none;
  border: none;
  margin: 0px;
  border-radius: 0;
  padding: 5px;
}
.group-action .btn .icon-delete,
.group-action .btn .c2 {
  color: #ff7285;
}
.group-action .btn .icon-edit,
.group-action .btn .c1 {
  color: #11817E;
}
.group-action .btn .icon-export {
  color: #55d8fe;
  font-size: 20px;
}
.group-action .ico {
  width: 20px;
  height: 15px;
  font-size: 16px;
}
.pagination-wrapper {
  background-color: #ffffff;
  padding: 15px;
}
.pagination-wrapper nav {
  display: inline-flex;
}
.pagination-wrapper .page-item:last-child .page-link,
.pagination-wrapper .page-item:first-child .page-link {
  border-radius: 6px;
}
.pagination-wrapper .pagination {
  border-radius: 0;
  margin-bottom: 0;
}
.pagination-wrapper .pagination li a {
  padding: 5px 8px;
  margin: 0 3px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 6px;
  line-height: 1;
  color: #4d4f5c;
  font-size: 13px;
}
.pagination-wrapper .pagination li a:hover {
  background-color: #11817E;
  border-color: #11817E;
  color: #fff;
}
.pagination-wrapper .page-item.active .page-link {
  background-color: #11817E;
  border-color: #11817E;
  color: #fff;
}
.filter-page {
  margin-left: 10px;
  display: inline-flex;
}
.filter-page .form-control {
  padding: 2px 8px;
  color: #4d4f5c;
  cursor: pointer;
  font-size: 13px;
}
.sidebar-wrapper .dropdown-item.active,
.sidebar-wrapper .dropdown-item:active {
  background-color: transparent;
}
.sidebar-wrapper .dropdown-item:hover,
.sidebar-wrapper .dropdown-item:focus {
  background-color: transparent;
  color: #fff;
}
.sidebar-wrapper .dropdown-item {
  color: #8a9a97;
  font-size: 13px;
  padding: 8px 0 8px 40px;
}
.sidebar-wrapper .nav li > a[aria-expanded="false"]::after {
  display: inline;
  text-align: right;
  padding-left: 5px;
}
.fixed-yellow {
  background: #11817E;
  padding: 5px 30px;
  font-size: 16px;
  position: fixed;
  bottom: 25px;
  right: 50px;
}
.fixed-blue {
  background: #11817E;
  padding: 5px 30px;
  font-size: 16px;
  position: fixed;
  bottom: 25px;
  right: 150px;
}
.btn-submit {
  background: #11817E;
  padding: 5px 30px;
  font-size: 16px;
}
.b-green {
  background-color: #4ad991;
}
.b-blue {
  background-color: #55d8fe;
}
/* The form-cst-check */
.form-cst-check {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: #43425d;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.form-cst-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 0;
  border: 1px solid #808495;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.form-cst-check:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.form-cst-check input:checked ~ .checkmark {
  background-color: #11817E;
  border: 1px solid #11817E;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.form-cst-check input:checked ~ .checkmark:after {
  display: block;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: #11817E !important;
  color: #11817E !important;
}
/* Style the checkmark/indicator */
.form-cst-check .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-section {
  min-height: calc(100vh - 12px);
  background-color: #fff;
  padding: 20px;
  position: relative;
}
.form-section .sub-wrapper {
  position: absolute;
  right: 5%;
}
.form-section .sub-wrapper .btn-submit {
  margin: 0;
}
.form-section radio-wrapper {
  margin: 20px 0;
}
.form-section radio-wrapper label {
  margin-bottom: 0;
}
.btn-append {
  margin: 0;
  border-radius: 0.25rem;
  padding: 9px 18px;
  background-color: transparent;
  color: #dedddd;
  border: 1px solid #dedddd;
}
.btn-append:hover {
  color: #11817E;
  border-color: #11817E;
  background-color: transparent;
}
.input-group-append {
  margin-left: -1px;
}
.bottom-nav {
  border-bottom: 1px solid #dedddd;
  margin-top: 10px;
}
.bottom-nav .nav-tabs {
  border: none;
}
.bottom-nav .nav-tabs .nav-link:hover,
.bottom-nav .nav-tabs .nav-link:focus {
  border: none;
}
.bottom-nav .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  color: #4D4F5C;
  border-bottom: 3px solid #11817E;
}
.bottom-nav .nav-tabs .nav-item {
  margin-bottom: 0;
}
.bottom-nav .nav li {
  margin-right: 5px;
}
.bottom-nav .nav li a {
  color: #9f9ea5;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  text-align: center;
  text-decoration: none;
}
.form-filds-wrapper {
  margin-top: 20px;
  position: relative;
}
.ab-right {
  position: absolute;
  top: 5px;
  right: 30px;
}
.ab-right-80{
  position: absolute;
  top: 12px;
  right: 80px;
}
.custominputGroup .btn {
  margin: 0;
  padding: 9px 20px;
  background-color: transparent;
  color: #4D4F5C;
  border: 1px solid #dedddd;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custominputGroup .show > .btn.dropdown-toggle,
.custominputGroup .btn:focus,
.custominputGroup .btn:not(:disabled):not(.disabled):active,
.custominputGroup .btn:not(:disabled):not(.disabled).active,
.custominputGroup .btn:not(:disabled):not(.disabled):active:focus {
  background-color: transparent;
  color: #4D4F5C;
  border: 1px solid #dedddd;
}
.custominputGroup .btn:not([data-action]):hover {
  box-shadow: none;
}
.control-label {
  font-size: 14px;
  color: #4D4F5C;
  display: block;
  margin-bottom: 5px;
}
.fr-box.fr-basic .fr-element {
  min-height: 120px;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  box-shadow: none;
  border: 1px solid #dedddd;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.fr-toolbar.fr-top {
  box-shadow: none;
  border: 1px solid #dedddd;
  border-bottom: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.fr-quick-insert {
  display: none;
}
.file-wrapper .uploader {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-right: 10px;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 110px;
}
.file-wrapper .uploader input {
  display: none;
}
.file-wrapper .uploader img {
  left: 50%;
  opacity: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  transition: all 300ms ease-in;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.file-wrapper .uploader img.loaded {
  opacity: 1;
}
.flex-wrapper .flex-div {
  display: inline-block;
  margin-right: 15px;
}
.dis-wrapper p {
  color: #4D4F5C;
  font-size: 14px;
  font-weight: 400;
}
.css-vj8t7z {
  min-height: 33px !important;
  border-color: #dedddd !important;
  border-radius: 0.25rem !important;
}
.css-1492t68,
.css-xp4uvy,
.css-1wy0on6,
.css-15k3avv {
  font-size: 14px !important;
  color: #4D4F5C !important;
}
.css-15k3avv {
  z-index: 99999 !important;
}
.css-1hwfws3 {
  cursor: pointer;
}
.css-1ep9fjw {
  padding: 7px;
}
.css-2o5izw {
  border-color: #11817E !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  font-size: 14px !important;
}
.css-z5z6cw {
  background-color: #11817E !important;
  font-size: 14px !important;
}
.css-wqgs6e {
  background-color: #fcf3cc !important;
  font-size: 14px !important;
}
.check-wrapper {
  margin-bottom: 15px;
}
.check-wrapper .form-check {
  padding-left: 0;
}
.packing-wrapper {
  margin: 20px 0;
}
.btn-simple {
  padding: 3px 15px;
  background-color: transparent;
  color: #dedddd;
  border-color: #dedddd;
}
.btn-cancel {
  padding: 5px 15px;
  background-color: transparent;
  color: #4D4F5C;
  border: 1px solid #dedddd;
}
.input-group-preppend {
  margin-right: -1px;
}
.css-xwjg1b {
  background-color: #fcf3cc !important;
  border-radius: 0.25rem !important;
}
.form-border-wrapper {
  border: 1px solid #dedddd;
  padding: 0 15px 20px;
  margin: 20px 0;
  border-radius: 0.25rem;
}
.form-border-wrapper .pad {
  padding: 20px 0;
}
.common-modal .modal-title {
  font-size: 16px;
  color: #4D4F5C;
}
.common-modal .dis p {
  color: #4D4F5C;
  margin: 0;
  line-height: 1.4;
  font-size: 14px;
}
.group-name {
  background: #fcf3cc;
  padding: 3px 10px;
  border-radius: 0.25rem;
  margin-left: 10px;
}
.table-product-wrapper {
  background-color: #ffffff;
  min-height: calc(100vh - 12px);
}
.table-product-wrapper .table {
  margin-bottom: 0;
}
.table-product-wrapper .table td {
  border-top: 0;
  border-bottom: 1px solid #f1f1f3;
}
.table-product-wrapper .table > thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  color: #a4a7b5;
}
.table-product-wrapper .table > tbody > tr > td {
  color: #4D4F5C;
  font-size: 13px;
  margin: 5px;
}
.table-product-wrapper thead {
  background-color: #f5f6fa;
}
.table-product-wrapper .nameImg .img-ico {
  width: 50px;
  height: 40px;
  border-radius: 6px;
  margin-right: 10px;
}
.table-product-wrapper .nameImg .img-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.table-product-wrapper .nameImg .tag-name {
  background: #fcf3cc;
  padding: 3px 10px;
  border-radius: 12px;
  margin-right: 10px;
  text-transform: uppercase;
}
.table-product-wrapper .logo-img {
  width: 14px;
}
.grey-bg-btn {
  cursor: pointer;
  background: #f0f0f7;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.grey-bg-btn .fa {
  color: #bbbbcb;
  font-size: 17px;
  padding: 7px 8px;
}
.green-bg-btn {
  cursor: pointer;
  background: #daf7e8;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.green-bg-btn .fa {
  color: #4ad991;
  font-size: 17px;
  padding: 7px 8px;
}
.inner-table-wrapper {
  margin-top: 15px;
}
.inner-table-wrapper .table > thead > tr > th {
  font-size: 13px;
  font-weight: 400;
  color: #a4a7b5;
  padding: 8px 5px;
}
.inner-table-wrapper thead {
  background-color: #f5f6fa;
}
.inner-table-wrapper .table td {
  border-top: 0;
  vertical-align: middle;
  border-bottom: 1px solid #f1f1f3;
  padding: 8px 5px;
  color: #4D4F5C;
  font-size: 13px;
}
.inner-table-wrapper .form-control {
  max-width: 200px;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: inherit;
  background-color: inherit;
}
.btn-plus {
  padding: 5px 10px;
  background-color: transparent;
  color: #4D4F5C;
  border-color: #4D4F5C;
  font-size: 13px;
}
.btn-plus:hover {
  background-color: transparent;
  color: #11817E;
}
.btn-tsp {
  padding: 5px 10px;
  background-color: transparent;
  color: #dedddd;
  border-color: #dedddd;
}
.btn-tsp:hover {
  background-color: transparent;
}
.btn-tsp .fa {
  width: 15px;
  height: 15px;
}
.rc-color-picker-wrap {
  display: flex;
}
.rc-color-picker-trigger {
  border: 1px solid #dedddd;
  width: 22px;
  height: 22px;
}
.prvilages .check-wrapper .form-check {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}
.smal-heading {
  margin: 10px 0;
}
.smal-heading h6 {
  color: #a6a6ac;
  cursor: pointer;
}
.sidebar-wrapper .collapse {
  background: #373a36;
}
.dropdown-menu .dropdown-item,
.bootstrap-select .dropdown-menu.inner li a {
  cursor: pointer;
}
.dis-editor .fr-box.fr-basic .fr-element {
  min-height: 400px;
}
.dis-editor .form-control {
  padding: 15px;
}
.variants-wrapper {
  border: 1px solid #dedddd;
  padding: 0 15px 20px;
  margin: 20px 0;
  border-radius: 0.25rem;
}
.bell-wrapper .badge:empty {
  display: inline-block;
  background-color: #ffc06a;
  padding: 4px;
  border-radius: 50%;
}
.notification-wrapper .img-w {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
  float: left;
}
.notification-wrapper .img-w img {
  width: 100%;
  height: 100%;
  display: block;
  background: #dedddd;
  border-radius: 50%;
}
.notification-wrapper .dropdown-menu {
  width: 275px;
}
.notification-wrapper .dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f1f1f3;
}
.notification-wrapper .noti-wrapper {
  white-space: normal;
  display: table;
}
.notification-wrapper .noti-wrapper p {
  margin: 0 5px;
  float: left;
  display: block;
  width: 80%;
  color: #4d4f5c;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.notification-wrapper .noti-wrapper p .dark-text {
  font-weight: 500;
  display: block;
}
.notification-wrapper .noti-wrapper p .d-t {
  font-weight: 300;
  color: #9f9ea5;
  font-size: 12px;
  margin-left: 10px;
}
.notification-wrapper .dropdown-header {
  padding: 0.5rem 1rem;
}
.notification-wrapper .dropdown-header .noti-link {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  display: block;
  text-decoration: none;
}
.notification-wrapper .dropdown-header .noti-link:hover {
  color: #11817E;
}
.list-notifications {
  background: #ffffff;
  padding: 10px 0;
  min-height: 500px;
}
.list-notifications .row {
  margin-left: -5px;
  margin-right: -5px;
}
.list-notifications [class*=col-] {
  padding: 0 5px;
}
.list-notifications .noti-wrapper {
  padding: 15px 10px;
  border: 1px solid #f1f1f3;
  width: 100%;
  margin: 0 0 10px 0;
  position: relative;
}
.list-notifications .noti-wrapper p {
  word-break: break-word;
  overflow: inherit;
  white-space: inherit;
}
.faq-listing-wrapper {
  min-height: calc(100vh - 12px);
  padding: 15px;
  background-color: #ffffff;
}
.faq-listing-wrapper .faq-section {
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  padding: 5px 10px;
  margin-bottom: 10px;
}
.faq-listing-wrapper .faq-section .faq-head {
  cursor: pointer;
}
.faq-listing-wrapper .faq-section .faq-head h4 {
  margin: 10px 0;
  color: #4D4F5C;
  font-size: 15px;
  font-weight: 600;
}
.faq-listing-wrapper .faq-section .faq-head .delFaq {
  display: block;
  float: right;
}
.faq-listing-wrapper .faq-section .faq-body p {
  color: #4D4F5C;
  font-size: 14px;
}
.faq-listing-wrapper .left-faq-menu {
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  padding: 5px 10px;
}
.faq-listing-wrapper .left-faq-menu .lists ul {
  list-style: none;
  padding: 0;
}
.faq-listing-wrapper .left-faq-menu .lists ul li {
  background: #fcf3cc;
  padding: 5px 10px;
  border-radius: 0.25rem;
  word-wrap: break-word;
  margin-bottom: 4px;
  color: #4D4F5C;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}
.faq-listing-wrapper .left-faq-menu .lists ul .active {
  color: #9f9ea5;
  font-weight: 600;
}
.faq-listing-wrapper .left-faq-menu .btn {
  float: right;
  position: relative;
  top: -5px;
}
.faq-listing-wrapper .add-btn-wraper {
  display: inline-block;
}
.privlages ul {
  list-style: none;
  padding-left: 0;
}
.privlages ul li {
  background: #fcf3cc;
  color: #4D4F5C;
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 12px;
  margin-bottom: 5px;
  margin-right: 10px;
  display: inline-flex;
}
.myaccount .profile {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.myaccount .profile img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
}
.myaccount h5 {
  color: #4D4F5C;
  font-size: 20px;
}
.myaccount .btn-plus {
  padding-left: 0;
}
.navbar a {
  text-decoration: none;
}
.bt-loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: rgba(255, 255, 255, 0.85) url(../img/Spinner.gif) no-repeat center center;
}
.bt-hide {
  display: none;
}
.bt-show {
  display: block;
}
.eye-btn {
  position: absolute;
top: 0;
right: 30px;
bottom: 0;
line-height: 40px;
font-size: 14px;
color: #777777;
cursor: pointer;
z-index: 9;
}
.PhoneInputInput{
  border-width: 0 !important;
}
.input-group-prepend .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected:hover {
  background: #11817E;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #4D4F5C;
}
.react-datepicker {
  border: 1px solid #dedddd;
}
.react-datepicker__header {
  border-bottom: 1px solid #dedddd;
}
.height-auto {
  min-height: auto;
}
.map__search-input-container {
  position: relative;
}
.map__clear-button,
.map__clear-button:active,
.map__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}
.map__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}
.map__suggestion-item {
  padding: 8px !important;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}
.map__suggestion-item--active {
  background-color: #fafafa;
}
.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}
.Demo__error-message {
  color: red;
}
.map__geocode-result-header {
  font-size: 20px;
  color: #222222;
}
.rdw-editor-main {
  border: 1px solid #dedddd;
  padding: 0 15px;
  min-height: 400px;
}
.add-childs {
  position: relative;
}
.add-childs .btn-abs {
  position: relative;
  float: right;
}
.public-DraftStyleDefault-block {
  margin: 5px 0 !important;
}
.p-bname {
  color: #4D4F5C;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: #f1f2f3;
  border-radius: 0.25rem;
}
.small-heading {
  font-size: 15px;
  color: #a2abca;
}
.form-control {
  font-size: 15px !important;
}
.del-wrapp .btn-tsp .fa {
  font-size: 14px;
}
.swal2-popup .swal2-title {
  font-size: 23px;
}
.delete-bt {
  position: absolute;
  z-index: 999;
  background: #FF7285;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: -10px;
  right: -5px;
}
.delete-bt .ico {
  font-size: 14px;
  color: #ffffff;
  padding: 2px 4px;
  display: block;
}
.t-red {
  color: #ff7285 !important;
}
.t-yellow {
  color: #11817E !important;
}
.s-red {
  background-color: #ff7285;
}
.s-green {
  background-color: #5ee2a0;
}
.s-orange {
  background-color: orange;
}
.s-grey {
  background-color: #b4b4c6;
}
.st-block {
  display: inline-block;
  padding: 4px 20px;
  color: #ffffff;
  border-radius: 0.25rem;
  margin-bottom: 5px;
  margin-left: 5px;
}
.order-wrapper {
  padding: 10px 0;
  min-height: calc(100vh - 12px);
  margin-bottom: 50px;
  margin-top: 40px;
}
.order-wrapper .filter-wrapper {
  background: #fff;
  position: fixed;
  top: 98px;
  left: 0;
  z-index: 999;
  width: 100%;
  padding-left: 240px;
}
.order-wrapper .full-order-wrapper {
  padding-top: 45px;
}
.order-wrapper .filter-wrapper .input-group {
  margin-bottom: 0;
  height: 32px;
}
.order-wrapper .filter-wrapper .form-control {
  background-color: #ffffff;
  padding: 6px 10px;
}
.order-wrapper .filter-wrapper .filter-search .form-control {
  border-right: 0;
}
.order-wrapper .filter-wrapper .form-control:focus + .input-group-append .input-group-text,
.order-wrapper .filter-wrapper .form-control:focus ~ .input-group-append .input-group-text {
  background-color: #ffffff;
}
.order-wrapper .filter-wrapper [class*="col-"] {
  padding: 0 5px;
}
.order-wrapper .filter-wrapper .row {
  margin-left: -5px;
  margin-right: -5px;
}
.tab-bg-wrapper {
  background-color: #ffffff;
  position: fixed;
  top: 53px;
  width: 100%;
  z-index: 999;
  left: 0;
  padding-left: 225px;
}
.tab-bg-wrapper .bottom-nav {
  border-top: 5px solid #dedddd;
  margin-top: 0;
  padding-top: 15px;
}
.tab-bg-wrapper .bottom-nav .nav-tabs .nav-link {
  color: #373a36;
}
.tab-bg-wrapper .bottom-nav .nav-tabs .nav-link.active {
  border-bottom: 3px solid #373a36;
  font-weight: 600;
}
.full-order-wrapper .footer-buttons-fixed {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  padding: 0 15px 0 240px;
}
.full-order-wrapper .footer-buttons-fixed .btn-submit {
  font-size: 14px;
}
.full-order-wrapper .table-product-wrapper {
  min-height: auto;
}
.full-order-wrapper .bg-white-wrapper {
  background-color: #ffffff;
  padding: 15px;
}
.full-order-wrapper .list-order {
  background-color: #ffffff;
  border: 1px solid #f1f1f3;
  height: calc(100vh - 200px);
  position: fixed;
  width: 19%;
}
.full-order-wrapper .list-order ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.full-order-wrapper .list-order ul li.active {
  background-color: #11817E;
}
.full-order-wrapper .list-order ul li.active p {
  color: #ffffff;
}
.full-order-wrapper .list-order ul li.active .orderId {
  color: #ffffff;
}
.full-order-wrapper .list-order ul li.active .small {
  color: #ffffff;
}
.full-order-wrapper .list-order .item {
  padding: 10px;
  border-bottom: 5px solid #f1f1f3;
  cursor: pointer;
}
.full-order-wrapper .list-order .item p {
  margin: 0;
  color: #373a36;
  font-size: 14px;
}
.full-order-wrapper .list-order .item .orderId {
  font-weight: 700;
}
.full-order-wrapper .list-order .item .small {
  font-size: 12px;
}
.full-order-wrapper .item:last-child {
  border-bottom: 5px solid #f1f1f3;
}
.full-order-wrapper .order-detail-wrapper .bd-highlight {
  width: 33.333%;
}
.full-order-wrapper .order-detail-wrapper .bd-highlight h5 {
  font-size: 16px;
  color: #373a36;
  font-weight: 700;
}
.full-order-wrapper .order-detail-wrapper .bd-highlight p {
  font-size: 13px;
  color: #4D4F5C;
  margin: 0;
}
.full-order-wrapper .order-detail-wrapper .link-green {
  color: #373a36;
  font-size: 14px;
  color: #5ee2a0;
}
.full-order-wrapper .status-changer .form-control {
  max-width: 200px;
  font-size: 13px !important;
  padding: 5px;
}
.full-order-wrapper .table-product-wrapper .sku {
  font-size: 10px;
}
.full-order-wrapper .table-product-wrapper tfoot p {
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  color: #4D4F5C;
}
.full-order-wrapper .table-product-wrapper .total {
  color: #373a36;
  font-weight: 700;
  font-size: 16px;
}
.full-order-wrapper .table-product-wrapper .table > thead > tr > th {
  color: #373a36;
  font-weight: 700;
}
.navbar.navbar-absolute {
  position: fixed;
  padding-left: 225px;
}
.table-orders p {
  font-size: 13px;
  color: #4D4F5C;
  margin: 0;
}
.table-orders .form-control {
  font-size: 13px !important;
  padding: 5px;
}
.top-box {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 10px;
}
.top-box .box-wrap {
  background-color: #11817E;
  color: #ffffff;
  padding: 10px;
}
.top-box .box-wrap .name {
  font-size: 14px;
  margin-bottom: 10px;
}
.top-box .box-wrap .box .small-name {
  font-size: 12px;
}
.top-box .box-wrap .box .price {
  font-size: 20px;
}
.common-filter-wrapper {
  background-color: #ffffff;
  padding: 10px;
}
.common-filter-wrapper .input-group {
  margin-bottom: 0;
  height: 32px;
}
.common-filter-wrapper .form-control {
  background-color: #ffffff;
  padding: 6px 10px;
}
.common-filter-wrapper .filter-search .form-control {
  border-right: 0;
}
.common-filter-wrapper .form-control:focus + .input-group-append .input-group-text,
.common-filter-wrapper .form-control:focus ~ .input-group-append .input-group-text {
  background-color: #ffffff;
}
.common-filter-wrapper [class*="col-"] {
  padding: 0 5px;
}
.common-filter-wrapper .row {
  margin-left: -5px;
  margin-right: -5px;
}
.react-datepicker__time-container {
  width: 90px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 90px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  font-weight: 400;
}
.max-content {
  max-width: 400px;
}
@media (max-width: 991px) {
  .navbar.navbar-absolute {
    padding-left: 0;
  }
  .tab-bg-wrapper,
  .order-wrapper .filter-wrapper {
    padding-left: 15px;
  }
  .full-order-wrapper .list-order {
    width: 22%;
  }
  .st-block {
    display: inline-block;
    padding: 2px 8px;
  }
  .full-order-wrapper .footer-buttons-fixed .btn-submit {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  st-block {
    display: inline-block;
    padding: 1px 2px;
  }
}
@media (min-width: 981px) {
  .full-order-wrapper [class*="col-"] {
    padding: 0 5px;
  }
  .full-order-wrapper .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.table .form-check label .form-check-sign::before,
.table .form-check label .form-check-sign::after {
  top: 0;
  left: 0;
}
@media (min-width: 1440px) {
  .tab-bg-wrapper,
  .navbar.navbar-absolute {
    padding-left: 280px;
  }
  .order-wrapper .filter-wrapper {
    padding-left: 280px;
  }
}
