
.login-wrapper {
    background-color: #ffffff
}

.login-wrapper .img-logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin: 0 auto
}

.login-wrapper .login-dis {
    color: #a5a6ac;
    font-size: 14px;
    padding: 0 10px 30px;
    text-align: center;
}

.login-wrapper .login-box {
    margin: 0 auto
}

.login-wrapper .login-box .logo {
    margin-bottom: 30px;
    text-align: center
}

.login-wrapper .login-box .logo img {
    max-width: 200px;
    margin: 0 auto
}

.login-wrapper .login-box .form-control {
    border-radius: 0.25rem;
    box-shadow: none;
    border: 1px solid #dedddd;
    font-size: 14px;
    color: #000;
    font-weight: 300;
}

.login-wrapper .login-box .form-control:focus {
    border: 1px solid #11817E
}

.login-wrapper .login-box .bt-wrapper {
    margin-top: 30px;
    text-align: center
}

.login-wrapper .login-box .btn {
    box-shadow: none;
    display: inline-block;
    background-color: #11817E;
    font-size: 15px;
    padding: 8px 35px;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer
}

.login-wrapper .login-box .btn:hover {
    background-color: #133F3B;
    color: #fff
}

.login-wrapper::-webkit-input-placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 300
}

.login-wrapper::-moz-placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 300
}

.login-wrapper:-ms-input-placeholder {
    color: #000;
    font-weight: 300;
    font-size: 14px
}

.login-wrapper:-moz-placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 300
}

.help-block {
    color: #dd0404;
    font-weight: 300;
    font-size: 10px;
    display: block;
    padding: 0 5px;
    margin: 0
}

.mb-20 {
    margin-bottom: 20px
}

.width-cst {
    max-width: 350px;
    margin: 0 auto
}

.bg-white {
    background: #fff
}

.bg-yellow {
    background: #11817E;
    height: 100vh
}



.radio-wrapper input[type=radio] {
    display: none
}

.radio-wrapper input[type=radio]+label>span {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 3px 6px 3px 3px;
    border: 2px solid #11817E;
    border-radius: 8px;
    vertical-align: bottom;
    cursor: pointer
}

.radio-wrapper input[type=radio]:checked+label>span>span {
    display: block;
    width: 7px;
    height: 7px;
    margin: 2px;
    border: 2px solid #11817E;
    border-radius: 5px;
    background: #11817E
}

.radio-wrapper label {
    color: #43425d;
    font-size: 13px;
    margin-right: 15px;
    cursor: pointer
}
.login-wrapper .forgot {
    text-align-last: right;
}


.login-wrapper .forgot a {
  display: inline-block;
  text-decoration: none;
  color: #43425d;
    font-size: 13px;
}
.login-wrapper .forgot a:hover {
  color: #11817E;
}
@media only screen and (min-width:768px) {
    .login-wrapper .container-fluid {
        padding: 0
    }
    .login-wrapper [class*="col-"] {
        padding: 0
    }
    .login-wrapper .row {
        margin-left: 0px;
        margin-right: 0px
    }
}

@media only screen and (max-width:768px) {
    .bg-yellow {
        height: auto;
        padding: 50px 0
    }
    .login-wrapper .img-logo {
        max-width: 250px
    }
    .login-form-wrapper {
        max-width: 350px;
        margin: 0 auto;
        padding: 50px 0
    }
}
